import React, { useState } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import './LoginModal.css';
import { Auth } from 'aws-amplify';

Modal.setAppElement('#root');

const LoginModal = ({ isOpen, onRequestClose, onLoginSuccess }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(''); // Reset error message

    try {
      // Autenticación usando AWS Cognito
      const user = await Auth.signIn(email, password);
      
      // Si la autenticación es exitosa, llamamos a onLoginSuccess
      onLoginSuccess();

      const token = user.signInUserSession.idToken.jwtToken;
      localStorage.setItem('token', token);
      
      // Redirigimos al usuario al dashboard
      navigate('/dashboard');
    } catch (err) {
      console.error('Error de autenticación:', err); // Registra el error completo en la consola
      if (err.code === 'UserNotConfirmedException') {
        setError('El usuario no ha confirmado su cuenta. Revisa tu correo para confirmar.');
      } else if (err.code === 'NotAuthorizedException') {
        setError('Credenciales incorrectas. Por favor, verifica tu email y contraseña.');
      } else if (err.code === 'UserNotFoundException') {
        setError('Usuario no encontrado. Por favor, regístrate primero.');
      } else {
        setError('Error en la autenticación. Verifique sus credenciales.');
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="modal"
      overlayClassName="overlay"
    >
      <div className="login-modal">
        <h2>Login</h2>
        {error && <p className="error">{error}</p>}
        <form onSubmit={handleLogin}>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit">Login</button>
        </form>
      </div>
    </Modal>
  );
};

export default LoginModal;
